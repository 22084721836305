import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faEnvelope } from '@fortawesome/fontawesome-free-solid/faEnvelope';

import { basePurple } from '../styles-settings';
import { FB_URL, EMAILHREF, EMAIL } from '../../../settings';


const Root = styled.div`
  text-align: right;
  margin: 0 1rem;
`;

const IconWrapper = styled.a`
  display: inline-block;
  padding: 0.5rem 0.75rem;

  &:hover {
    cursor: pointer;
    color: ${basePurple};
  }
`;

function TopPanel() {
  return (
    <Root>
      <IconWrapper href={FB_URL} target="_blank" rel="nofollow noreferrer noopener" title="Facebook Dysplan">
        <FontAwesomeIcon icon={faFacebook} />
      </IconWrapper>
      <IconWrapper href={EMAILHREF} title={`Email: ${EMAIL}`}>
        <FontAwesomeIcon icon={faEnvelope} />
      </IconWrapper>
    </Root>
  );
}

export default TopPanel;