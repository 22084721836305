import styled from "styled-components";


const Root = styled.div`
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: ${(props) => props.overlayColor};
  }
`;

Root.defaultProps = {
  overlayColor: 'rgba(0,0,0,0.5)',
};

export default Root;
