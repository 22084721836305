import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { basePurple, minBreakpoint } from './styles-settings';
import Container from './Container';


const underlineHeader = css`
  &:after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: -0.3rem;
    right: -0.3rem;
    height: 0.15rem;
    background: ${basePurple};
  }
`;

export const HeaderH2 = styled.h2`
  display: inline-block;
  font-size: 2em;
  position: relative;
  ${underlineHeader};
`;

export const HeaderH3 = styled.h3`
  display: inline-block;
  font-size: 2em;
  position: relative;
  ${underlineHeader};
`;

export const ContactLink = styled.a`
  color: ${basePurple};
  text-decoration: none;

  &:hover, &:focus, &:visited, &:target {
    color: ${basePurple};
  }
`;

export const ContactGatsbyLink = styled(Link)`
  color: ${basePurple};
  text-decoration: none;

  &:hover, &:focus, &:visited, &:target {
    color: ${basePurple};
  }
`;

export const HeaderH3Description = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

export const SectionStyled = styled(Container)`
  padding: 4rem 1rem;
  text-align: center;

  @media (min-width: ${`${minBreakpoint}px`}) {
    padding: 4rem 2rem;
  }
`;

export const fancyBackground = css`
  background:
    linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
    linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px;
`;
