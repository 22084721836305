const urls = new Map([
  ['home', '/'],
  ['oferta', '/oferta/'],
  ['plandeki', '/plandeki/'],
  ['banery', '/banery/'],
  ['oklejaniePojazdow', '/oklejanie-pojazdow/'],
  ['wielkoformatowy', '/druk-wielkoformatowy/'],
  ['kasetony', '/kasetony-swietlne/'],
  ['flagiMaszty', '/flagi-maszty/'],
  ['namioty', '/namioty/'],
  ['zleceniaNietypowe', '/zlecenia-nietypowe/'],
  ['uslugiPoligraficzne', '/uslugi-poligraficzne/'],
  ['tabliceReklamowe', '/tablice-reklamowe/'],
  ['konstrukcjeStalowe', '/konstrukcje-stalowe/'],
  ['systemyPrzenosne', '/systemy-przenosne/'],
]);

export default urls;
