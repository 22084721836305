import React from 'react';
// This ensures that the icon CSS is loaded immediately before attempting to render icons
// https://github.com/FortAwesome/react-fontawesome/issues/134#issuecomment-471940596
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import FixedButton from './FixedButton';
import Container from './Container';
import TopPanel from './TopPanel';
import Cookies from './Cookies';
import './layout.css';
import './index.css';
import './commons.css';
import { baseBlack, baseGray } from './styles-settings';


const BodyStyles = styled.div`
  background-color: ${baseBlack};
  min-height: 100vh;
  color: ${baseGray};
`;

const Layout = ({ children }) => {
  // Prevent fontawesome from dynamically adding its css since we did it manually above
  config.autoAddCss = false;
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <BodyStyles>
          <Helmet
            title="Dysplan - solidne plandeki i trwałe banery wykonane w Krakowie"
            meta={[
              {
                name: 'description',
                content:
                  'Dysplan - solidnie i terminowo wykonane plandeki, banery, reklamy Kraków',
              },
              {
                name: 'keywords',
                content:
                  `plandeki, plandeki Kraków, banery, banery Kraków, banery Małopolska,
                   reklamy Kraków, reklamy Małopolska`,
              },
              { name: 'robots', content: 'index, follow' },
            ]}
          />
          <Container>
            <TopPanel />
            <Header />
            {children}
            <Footer />
            <FixedButton />
          </Container>
          <Cookies />
        </BodyStyles>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
