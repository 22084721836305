import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid/faTimes';

import { CookiesWrapper, CookiesText, CookiesCloseButton } from './styles';


function Cookies() {
  const [cookies, setCookie] = useCookies(['name']);
  const [isCookiesClicked, setIsCookiesClicked ] = useState(false);
  const policyName = 'AcceptPolicy';

  function onChange() {
    setCookie(policyName, true, { path: '/', maxAge: '31104000' });
    setIsCookiesClicked(true);
  };

  return (
    <React.Fragment>
      { (cookies.AcceptPolicy !== 'true' && !isCookiesClicked) &&
        <CookiesWrapper>
          <CookiesText>
            W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie zmiany ustawień dotyczących cookies.
          </CookiesText>
          <CookiesCloseButton
            onClick={onChange}
          >
            <FontAwesomeIcon icon={faTimes} />
          </CookiesCloseButton>
        </CookiesWrapper>
      }
    </React.Fragment>
  );
}

export default Cookies;