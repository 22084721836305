export const basePurple = '#9a0090';
export const baseGray = '#a6a7ab';
export const baseBlack = '#000';
export const baseWhite = '#fff';
export const palePurple = '#784fa3';
export const darkPurple = '#4d2638';

export const minBreakpoint = 600;
export const smallBreapoint = 960;
export const smallBreapointMax = 959;
export const normalBreakpoint = 1280;
export const mediumBreakpoint = 1900;
export const largeBreakpoint = 2560;
