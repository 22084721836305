import styled from 'styled-components';

import { CloseMobileButton } from '../Navigation/index';
import { baseBlack } from '../styles-settings';


export const CookiesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 1.5rem;
  background: #fff;
  display: flex;
`;

export const CookiesText = styled.p`
  margin-bottom: 0;
`;

export const CookiesCloseButton = styled(CloseMobileButton)`
  color: ${baseBlack};
`;