import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/fontawesome-free-solid/faArrowUp';

import ScrollTo from '../ScrollTo';
import { basePurple, baseBlack } from '../styles-settings';

const Root = styled.div`
  position: fixed;
  right: 5%;
  bottom: 10%;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  background: rgba(255,255,255, 0.7);
  height: 3rem;
  width: 3rem;
  padding: 0.25rem;
  position: relative;
  border: 2px solid ${basePurple};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${baseBlack};
`;

class FixedButton extends React.PureComponent {
  render() {
    return (
      <Root>
        <ScrollTo
          scrollToTop
        >
          <IconWrapper>
            <Icon icon={faArrowUp} />
          </IconWrapper>
        </ScrollTo>
      </Root>
    );
  }
}

export default FixedButton;


