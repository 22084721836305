import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/fontawesome-free-solid/faBars';

import {baseWhite, smallBreapoint} from '../styles-settings';


const Root = styled.button`
  padding: 1rem;
  font-size: 1.5rem;
  color: ${baseWhite};
  border: 0;
  background: transparent;
  
  &:hover {
    cursor: pointer;
  }
  
  @media (min-width: ${`${smallBreapoint}px`}) {
    display: none;
  }
`;

class Hamburger extends React.Component {
  
  handleHamburgerClick = () => {
    this.props.navigationClick();
  };
  
  render() {
    return (
      <Root
        onClick={this.handleHamburgerClick}
      >
        <FontAwesomeIcon icon={faBars} />
      </Root>
    );
  }
}

export default Hamburger;