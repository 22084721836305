import React from 'react';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid/faTimes';
import styled, { css } from 'styled-components';
import { navigate } from '@reach/router';

import { basePurple, baseWhite, smallBreapoint, smallBreapointMax } from '../styles-settings';
import { fancyBackground } from '../commons-styles';
import urls from '../../data/urls';


const Root = styled.div`
  @media (max-width: ${`${ smallBreapointMax }px`}) {
    position: fixed;
    top: 0;
    right: ${(props) => props.open ? '0' : '100%'};
    ${fancyBackground};
    z-index: 1100;
    padding: 4rem 2rem;
    height: 100%;
    width: 100%;
    transition: right 1s;
  }

  @media (min-width: ${`${ smallBreapoint }px`}) {
    display: block;
    position: static;
    background: transparent;
    padding: 0;
  }
`;

const NavigationStyles = styled.nav`
  display: flex;
  align-items: center;
`;

const NavigationListStyles = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  @media (max-width: ${`${ smallBreapointMax }px`}) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem 0;
    
    li {
      width: 100%;
      margin-bottom: 2rem;
    }
    
    a, span {
      font-size: 2rem !important;
      width: 100%;
    }
  }
`;

const NavigationListElementStyles = styled.li`
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:after {
    opacity: 0;
    transition: opacity 1s, transform 1s;
    transform: translateX(-100%);
    content: "";
    position: absolute;
    bottom: 0;
    left: -0.3rem;
    right: -0.3rem;
    height: 2px;
    background: ${basePurple};
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const linkStyles = css`
  padding: 1rem;
  color: ${baseWhite};
  text-decoration: none;
  display: inline-block;
  font-size: 1.3rem;

  &:hover {
    cursor: pointer;
    color: ${baseWhite};
  }
`;

const LinkStyled = styled(Link)`
  ${linkStyles};
`;

const NavSpanStyled = styled.span`
  ${linkStyles};
`;

const CloseMobileButtonWrapper = styled.div`
  text-align: right;
  
  @media (min-width: ${`${smallBreapoint}px`}) {
    display: none;
  }
`;

export const CloseMobileButton = styled.button`
  background: transparent;
  border: 0;
  color: ${baseWhite};
  font-size: 1.5rem;
  padding: 1rem;
  
  &:hover {
    cursor: pointer;
  }
`;

const dataNav = [
  {
    id: '1',
    to: '/',
    hash: '#o_nas',
    text: 'O nas',
  },
  {
    id: '2',
    to: urls.get('oferta'),
    hash: '',
    text: 'Oferta',
  },
  {
    id: '3',
    to: '/',
    hash: '#galeria',
    text: 'Galeria',
  },
  {
    id: '4',
    to: '/',
    hash: '#kontakt',
    text: 'Kontakt',
  },
];

class Navigation extends React.PureComponent {
  handleHamburgerClick = (hash, to) => {
    if (hash.length > 0) {
      navigate(`${to}${hash}`)
    }
    this.props.navigationClick();
  };
  
  render() {
    const { open } = this.props;
    return(
      <Root
        open={open}
      >
        <CloseMobileButtonWrapper>
          <CloseMobileButton
            onClick={this.handleHamburgerClick}
          >
            <FontAwesomeIcon icon={faTimes} />
          </CloseMobileButton>
        </CloseMobileButtonWrapper>
        <NavigationStyles className="nav">
          <NavigationListStyles
            className="nav-list"
            open={open}
          >
            {
              dataNav.map((navElem) => (
                <NavigationListElementStyles className="nav-elem" key={navElem.id}>
                  { navElem.hash.length === 0 ? (
                    <LinkStyled
                      to={navElem.to}
                      onClick={() => this.handleHamburgerClick(navElem.hash, navElem.to)}
                    >
                      {navElem.text}
                    </LinkStyled>
                  ) : (
                    <NavSpanStyled
                      onClick={() => this.handleHamburgerClick(navElem.hash, navElem.to)}
                    >
                      {navElem.text}
                    </NavSpanStyled>
                  )}
                </NavigationListElementStyles>
              ))
            }
          </NavigationListStyles>
        </NavigationStyles>
      </Root>
    );
  }
}

export default Navigation
