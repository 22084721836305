import React from 'react';
import styled from 'styled-components';

import { SectionStyled, fancyBackground } from '../commons-styles';
import Overlay from '../Overlay';
import { basePurple, baseWhite } from '../styles-settings';
import Navigation from '../Navigation';


const FancyBackground = styled.div`
  ${fancyBackground};
`;

const FooterStyled = styled.footer`
  border-top: 2px solid ${basePurple};
`;

const NavigationStyled = styled(Navigation)`
  justify-content: center;
`;

const FooterHeader = styled.h4`
  font-size: 1.25rem;
  color: ${baseWhite};
`;

const FooterNavigation = styled.div`
  margin-bottom: 1.5rem;
  
  .nav {
    justify-content: center;
  }
  
  > * {
    justify-content: center;
  }
`;

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleNavigation: false,
    };
  }

  navigationClick = () => {
    this.setState(prevState => ({
      toggleNavigation: !prevState.toggleNavigation,
    }));
  };

  render() {
    return (
      <FancyBackground>
        <Overlay>
          <FooterStyled id="footer">
            <SectionStyled>
              {/* Sp. z.o.o */}
              <FooterHeader>Dysplan</FooterHeader>
              <FooterNavigation>
                <NavigationStyled
                  navigationClick={this.navigationClick}
                />
              </FooterNavigation>
              Powered by <a target="_blank" rel="noopener noreferrer" href="http://michal-winiarski.pl/">
              Michał Winiarski</a>
            </SectionStyled>
          </FooterStyled>
        </Overlay>
      </FancyBackground>
    );
  }
}

export default Footer;
