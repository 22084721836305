import React from 'react';
import Layout from '../components/layout';
import Container from '../components/Container';


const NotFoundPage = () => (
  <Layout>
    <Container breakpoint="small">
      <h1>Błąd 404 - strona nie znaleziona</h1>
      <p>
        Przepraszamy, ale url o takiej nazwie nie istnieje. Sprawdź czy
        poprawnie wpisałeś adres naszej strony ;)
      </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
