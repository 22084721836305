import styled from 'styled-components';
import PropTypes from 'prop-types';


const containerBreakpoints = new Map(
  [
    [
      'verysmall', 960
    ],
    [
      'small', 1280
    ],
    [
      'medium', 1700
    ],
  ]
);

const Container = styled.div`
  max-width: ${(props) => props.breakpoint ? containerBreakpoints.get(props.breakpoint) : 2560 }px;
  margin: auto;
`;

Container.propTypes = {
  breakpoint: PropTypes.string,
};

export default Container;
