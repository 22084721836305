import React from 'react';
import Link from 'gatsby-link';

import styled from "styled-components";
import logo from '../../images/logo.png';

const Root = styled(Link)`
  max-width: 15rem;
  color: #fff;
  display: inline-block;
`;

const Logo = () => (
  <Root
    to="/"
  >
    {/* Sp. z.o.o */}
    <img src={logo} alt="Firma Dysplan" title="Firma Dysplan" />
  </Root>
);

export default Logo