import React from 'react';
import styled from 'styled-components';

import Navigation from '../Navigation';
import Hamburger from '../Hamburger';
import Container from '../Container';
import Logo from '../Logo/index';
import { basePurple } from '../styles-settings';
import { fancyBackground } from '../commons-styles';

const WrapperLogoInHeader = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  border-bottom: 2px solid ${basePurple};
  ${fancyBackground};
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  `;

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
`;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleNavigation: false,
    }
  }
  
  navigationClick = () => {
    this.setState({
      toggleNavigation: !this.state.toggleNavigation,
    });
  };
  
  render() {
    return (
      <HeaderWrapper>
        <HeaderContainer breakpoints="small">
          <WrapperLogoInHeader>
            <Logo/>
          </WrapperLogoInHeader>
          <NavigationWrapper>
            <Hamburger navigationClick={this.navigationClick} />
            <Navigation
              open={this.state.toggleNavigation}
              navigationClick={this.navigationClick}
            />
          </NavigationWrapper>
        </HeaderContainer>
      </HeaderWrapper>
    );
  }
}

export default Header